import useScrollToTop from 'lib/hooks/useScrollToTop';
import Link from 'next/link';
import Head from 'next/head';
import styles from 'styles/pages/404.module.scss';

const FourZeroFour = () => {
	useScrollToTop();

	return (
		<div className={styles.container}>
			<Head>
				<title>404 Error</title>
			</Head>
			<div>
				<h1> Uh oh! </h1>
				<h3> there is nothing here... </h3>
				<Link href='/'>take me back to the homepage</Link>
				<p>(404 error)</p>
			</div>
		</div>
	);
};

export default FourZeroFour;
